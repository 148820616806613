import { Box } from "@mui/material";
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { useResponsive } from 'src/hooks/use-responsive';

import Logo from 'src/components/logo';

// ----------------------------------------------------------------------

const LINKS = [
  // {
  //   headline: 'Minimal',
  //   children: [
  //     { name: 'About us', href: paths.root },
  //     { name: 'Contact us', href: paths.root },
  //     { name: 'FAQs', href: paths.root },
  //   ],
  // },
  // {
  //   headline: 'Legal',
  //   children: [
  //     // { name: 'Terms and Condition', href: '#' },
  //     { name: 'Privacy Policy', href: paths.terms },
  //   ],
  // },
  // {
  //   headline: 'Contact',
  //   children: [{ name: 'hello@Voolist.com', href: 'mailto:hello@Voolist.com' }],
  // },
];

// ----------------------------------------------------------------------

export default function Footer () {
  const mdUp = useResponsive('up', 'md');
  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Divider />

      <Container
        sx={{
          pt: 4,
          pb: 4,
          textAlign: { xs: 'center', md: 'unset' },
        }}
      >
        {
          mdUp && <Logo sx={{ mb: 3 }} />
        }


        <Box sx={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'flex-start',
          flexDirection: 'row',
          alignContent: "center",

        }}>
          <Typography
            variant="body2"
            sx={{
              flex: 0.8,
            }}
          >
            Voolist is the best  cross listing tool for resellers.
            With AI generated listings, Sales detector, bulk delist/list and more, Voolist is the all you need.
          </Typography>

          <Box sx={{ flex: 1, ml: 7 }}>
            <Typography variant="body2" sx={{ width: "80%", mx: "auto" }}>
              The term &apos;Etsy&apos; is a trademark of Etsy, Inc. This application uses the Etsy API but is not endorsed or certified by Etsy, Inc.
            </Typography>
          </Box>
        </Box>
        <Typography variant="body2" sx={{ textAlign: "center", mt: 5 }}>
          © 2024. All rights reserved
        </Typography>


      </Container>
    </Box>
  );

  return mainFooter;
}
